// Perform the redirect logic
const redirectToNewApp = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const uid = urlParams.get('uid');
    const baseURL = 'https://veritace.currentbody.com/';

    if (uid) {
        // Redirect to the new URL with the 'uid' value
        window.location.href = `${baseURL}?uid=${uid}`;
    } else {
        // Redirect to the base URL without a UID
        window.location.href = baseURL;
    }
};

redirectToNewApp();
